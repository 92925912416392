import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {

    // TODO: Substituir exibição de erro em Alert para erro em modal
    // constructor(private modalService: ModalService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(

            catchError((errorResponse: HttpErrorResponse) => {

                let errorMessage = '';

                console.error(errorResponse);

                if (!(errorResponse.error instanceof ErrorEvent)) {

                    if (errorResponse.status === 0) {

                        // TODO: Substituir exibição de erro em Alert para erro em modal
                        //alert('Parece que a interface não está conseguindo se comunicar com o servidor.');
                        errorMessage = 'Parece que a interface não está conseguindo se comunicar com o servidor.';
                    }

                    if (errorResponse.status === 500 && !(errorResponse.error?.error?.type)) {

                        // TODO: verificar -> comentado em 14/03/2022 pois está lançando erro genérico, impedindo customização no frontend
                        // this.modalService.alert('Ops!', 'Parece que ocorreu um erro não esperado ao processar sua solicitação.');
                        errorMessage = 'Ops!, Parece que ocorreu um erro não esperado ao processar sua solicitação.';
                    }

                    if (errorResponse.status === 404) {
                        errorMessage = 'Objeto não encontrado.';
                    }

                    if (errorResponse.status === 400) {
                        errorMessage = this.getErrorMessage(errorResponse);
                    }

                    if (errorResponse.status === 401) {
                        errorMessage = this.getErrorMessage(errorResponse);
                    }
                    
                }

                //
                // Caso de problema no formato da resposta de erro

                if (errorMessage === '' && errorResponse?.error?.error?.message) {
                    errorMessage = errorResponse.error.error.message;
                }

                return throwError(() => new Object({ message: (errorMessage === '' ? errorResponse.error : errorMessage), status: errorResponse.status}));

            })

        );
    }

    //
    // Obter texto de mensagem de erro

    getErrorMessage(errorResponse: HttpErrorResponse): string {
        const errorObj = errorResponse.error;

        if (!errorObj) {
            return '';
        }

        //
        // Erro 400 padrão retornado do servidor

        const violations = errorObj.violations;

        if (violations) {
            return violations[0].message;  
        }

        //
        // Erro retornado com responseType definido como 'string' no frontend

        if (typeof errorObj === 'string') {
            return errorObj;
        }

        //
        // Erro retornado com responseType definido como 'arraybuffer' no frontend

        const arrayBufferErrorResponse: ArrayBuffer = errorObj;

        if (arrayBufferErrorResponse) {
            const decodedString = (new TextDecoder('utf-8')).decode(new Uint8Array(arrayBufferErrorResponse));
            const decodedObj = JSON.parse(decodedString);

            const errorMessage = decodedObj.violations[0].message;

            return errorMessage; 
        }

        //
        // Erro no frontend
        
        if (typeof errorObj === 'object' && errorObj.error) {
            return errorObj.error;
        }

        return 'Erro não tratado';
    }
}

<div class="logo">
    <fa-icon [icon]="faCity"></fa-icon>
    <span class="name"><em>REURB</em> Digital</span>
    <small class="version">beta</small>
</div>

<div class="menu">
    <a class="pro-button tertiary" pButton href="/nucleos-urbanos">Núcleos Urbanos</a>
    <a class="pro-button tertiary" pButton href="/lotes">Lotes</a>

    <div class="dropdown-menu" *ngIf="showConfigMenu">
        <button #btn type="button" class="pro-button tertiary" pButton (click)="menu.toggle($event)">Configurações<fa-icon style="margin-left: 5px;" [icon]="faDropDown"></fa-icon></button>

        <p-tieredMenu #menu [model]="menuItems" [popup]="true"></p-tieredMenu>
    </div>
</div>

<div class="auth">
    <span class="user-name">{{ currentUser.nome }}</span>
    <a class="logout" href="#" (click)="$event.preventDefault(); logout();" title="Logout"><fa-icon [icon]="faExit"></fa-icon></a>
</div>

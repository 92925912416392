import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SugestoesService } from '../../services/sugestoes.service';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-formulario-sugestoes',
  templateUrl: './formulario-sugestoes.component.html',
  styleUrls: ['./formulario-sugestoes.component.scss']
})
export class FormularioSugestoesComponent implements OnInit {

  submitted: boolean = false;
  form: FormGroup = new FormGroup('');

  @Output() closeCadastroSugestaoEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _sugestoesService: SugestoesService,
              private _messageService: MessageService,
              private fb: FormBuilder) {}

  ngOnInit(): void {
    
    this.form = this.fb.group({
      sugestao: [ '', Validators.required ]
    });

  }

  public saveSugestao(): void {
    this.submitted = true;

    if (this.form.valid) {
      const newSugestao: string = this.form.value.sugestao;

      this._sugestoesService.cadastrarSugestao(newSugestao).subscribe({
        next: () => { },
        error: (e: HttpErrorResponse) => {
          this._messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Erro',
            detail: 'Erro ao registrar sugestão: ' + e.message
          });
        },
        complete: () => {
          this._messageService.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Sugestão registrada.'
          });
  
          this.retornar();
        }
      });

    } else {
      this.markAllAsDirty(Object.values(this.form.controls));
    }
  }

  public cancelar(): void {
    this.retornar();
  }

  private retornar(): void {
    this.closeCadastroSugestaoEvent.emit();
  }

  // Marca todos os campos como "modificados", mudando o estilo do formulário
  // conforme sua verificação
  //
  private markAllAsDirty(control: AbstractControl[]): void {
    control.forEach((control) => {
      if (control instanceof FormControl) {
        control.markAsDirty();
      } else if (control instanceof FormGroup) {
        this.markAllAsDirty(Object.values(control.controls));
      } else if (control instanceof FormArray) {
        this.markAllAsDirty(control.controls);
      }
    });
  }

}

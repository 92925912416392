import { Component, OnInit } from '@angular/core';
import { User } from '../../auth/user';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { MessageService } from 'primeng/api';
import { faAngleDown, faCity, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { Role } from '../../auth/roles.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  currentUser: User = {} as User;

  private _subscription: Subscription = new Subscription();

  // Icons
  faCity = faCity;
  faExit = faRightFromBracket;

  faDropDown = faAngleDown;
  
  menuItems: { label: string, routerLink: string[], role: keyof typeof Role, visible: boolean }[] = [
    {
      label: 'Agentes',
      routerLink: ['agentes'],
      role: 'agentes-listar',
      visible: false
    },
    {
      label: 'Empresas de Coleta',
      routerLink: ['empresas'],
      role: 'empresascoleta-listar',
      visible: false
    }
  ];

  showConfigMenu: boolean = false;

  constructor(private _authService: AuthenticationService,
              private _messageService: MessageService) {}

  ngOnInit(): void {
    this._subscription = this._authService.getUser().subscribe({
      next: (user) => {
        this.currentUser = user;

        this.menuItems.forEach((menuItem) => {
          menuItem.visible = this.userContainsRole(menuItem.role);

          if (menuItem.visible === true)
            this.showConfigMenu = true;
        });
      },
      error: (error) => {
        this._messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Obter usuário do Keycloak',
          detail: 'Erro ao obter usuário do keycloak: ' + error.message
        });
      }
    });
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public doNothing(): void {
    console.log(Date.now);
  }

  public logout(): void {
    this._authService.logout();
  }

  public userContainsRole(roleName: keyof typeof Role): boolean {
    return this._authService.hasRole(Role[roleName]);
  }

}

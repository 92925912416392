import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.scss']
})
export class ModalConfirmationComponent implements OnInit, AfterViewInit {

  private _message: string = '';
  private _header: string = '';
  private _confirmButtonLabel: string = '';
  private _cancelButtonLabel: string = '';
  private _severity: string = '';

  private modalReturn: 'ACCEPT' | 'REJECT' = 'REJECT';

  @ViewChild('confirmButton')
  public confirmButton: ElementRef<HTMLButtonElement> = {} as ElementRef<HTMLButtonElement>;

  constructor(private ref: DynamicDialogRef,
              private config: DynamicDialogConfig) {}

  ngOnInit(): void {
    if (this.config?.data?.message) {
      this._message = this.config.data.message;
    }
    if (this.config?.data?.header) {
      this._header = this.config.data.header;
    }
    if (this.config?.data?.severity) {
      this._severity = this.config.data.severity;
    }
    if (this.config?.data?.confirmButtonLabel) {
      this._confirmButtonLabel = this.config.data.confirmButtonLabel;
    }
    if (this.config?.data?.cancelButtonLabel) {
      this._cancelButtonLabel = this.config.data.cancelButtonLabel;
    }
  }

  ngAfterViewInit(): void {
    this.confirmButton.nativeElement.focus();
    this.confirmButton.nativeElement.blur();
  }

  public cancelar(): void {
    this.modalReturn = 'REJECT';

    this.closeModal();
  }

  public confirmar(): void {
    this.modalReturn = 'ACCEPT';

    this.closeModal();
  }

  private closeModal(): void {
    this.ref.close(this.modalReturn);
  }

  get severityClass(): string {
    return this._severity;
  }

  get message(): string {
    return this._message;
  }

  get header(): string {
    return this._header;
  }

  get confirmButtonLabel(): string {
    return this._confirmButtonLabel;
  }

  get cancelButtonLabel(): string {
    return this._cancelButtonLabel;
  }

}

<div class="pro-page-section cadastrar-sugestao no-divider">
  
      <form class="pro-form cadastrar-sugestao not-inline" [formGroup]="form">

        <h3>Críticas e Sugestões</h3>

        <div class="grid w-full">

          <div class="pro-form-item col-12 sugestao required">
            <label for="sugestao">Descrição</label>
            <p style="margin-bottom: 10px">Descreva brevemente uma dificuldade que encontrou ou uma ideia que pode melhorar a utilização do sistema.</p>
            <textarea pInputTextarea id="sugestao" class="sugestao-textarea p-inputtextarea-w-full" formControlName="sugestao" [maxlength]="1000" 
                      (resize)="false" [autoResize]="false" rows="10" required pAutoFocus [autofocus]="true"></textarea>
            <small class="pro-feedback violation" *ngIf="submitted && form.controls['sugestao'].errors?.['required']">A sugestão não pode ser vazia</small>
          </div>
            
        </div>

        <div class="pro-form-submit">
            <button class="pro-button tertiary" pButton type="button" (click)="cancelar()">Cancelar</button>
            <button class="pro-button" pButton type="submit" (click)="saveSugestao()">Salvar</button>
        </div>

      </form>

</div>
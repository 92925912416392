import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ApiErrorInterceptor } from './core/interceptors/api-error-interceptor';
import { ApiAddressInterceptor } from './core/interceptors/api-address-interceptor';
import { AppErrorHandler } from './app-error-handler';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './core/layout/layout.module';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { SidebarModule } from 'primeng/sidebar';

import { SharedModule } from './shared/shared.module';

import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAngleDoubleRight, faCheck, faCheckCircle, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { DialogService } from 'primeng/dynamicdialog';
import { PdfViewerModule } from 'ng2-pdf-viewer';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    KeycloakAngularModule,
    CoreModule,
    LayoutModule,
    FontAwesomeModule,
    NgxMaskDirective,
    NgxMaskPipe,

    ToastModule,
    ConfirmDialogModule,
    SidebarModule,

    PdfViewerModule,

    SharedModule,

    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: ApiErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiAddressInterceptor, multi: true },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    provideNgxMask(),
    HttpClient,
    MessageService,
    DialogService,
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faCheck, faXmark, faCheckCircle, faXmarkCircle, faAngleDoubleRight);
  }
}

function initializeKeycloak(keycloak: KeycloakService) {

  return () =>
    keycloak.init({
      config: {
        url: environment.KEYCLOAK_URL,
        realm: environment.KEYCLOAK_REALM,
        clientId: environment.KEYCLOAK_CLIENT,
      },
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false,
      },
      enableBearerInterceptor: true,
      loadUserProfileAtStartUp: true
    })
      .then((auth) => {
        if (auth === true) {
          localStorage.setItem('loggedIn', 'true');
        }
      });
}

<main>
    <p-toast position="bottom-right" >
        <ng-template pTemplate="message" let-message>
            <div *ngIf="message.severity === 'error'" class="w-full">
                <div class="grid flex align-content-center flex-wrap">
                    <div class="col-1 flex">
                        <fa-icon [icon]="['fas', 'circle-xmark']"></fa-icon>
                    </div>
                    <div class="col-11 flex">
                        <h4 class="inline">{{message.summary}}. </h4>
                        <span>{{message.detail}}</span>
                    </div>
                </div>
            </div>

            <div *ngIf="message.severity === 'success'" class="w-full">
                <div class="grid align-content-center flex-wrap">
                    <div class="col-1 flex">
                        <fa-icon [icon]="['fas', 'check-circle']"></fa-icon>
                    </div>
                    <div class="col-11 flex">
                        <h4 class="inline">{{message.summary}}. </h4>
                        <span>{{message.detail}}</span>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-toast>

    <app-header></app-header>
    <app-current-environment></app-current-environment>

    <div class="app-router"><router-outlet></router-outlet></div>
    <app-footer></app-footer>

    <p-confirmDialog #confirmDialog [style]="{ width: '50vw', 'background-color': '#ffffff' }">
        <ng-template pTemplate="header">
            
        </ng-template>
        <ng-template pTemplate="footer">
            <p-button type="button" [ngClass]="'p-confirm-dialog-accept'" class="pro-button"
                [class]="confirmDialog.option('acceptButtonStyleClass')" label="Sim" (onClick)="confirmDialog.accept()"
                [attr.aria-label]="confirmDialog.acceptAriaLabel">
                <fa-icon [icon]="['fas', 'check']"></fa-icon>
            </p-button>
            <p-button type="button" [ngClass]="'p-confirm-dialog-reject'" class="pro-button"
                [class]="confirmDialog.option('rejectButtonStyleClass')" label="Não" (onClick)="confirmDialog.reject()"
                [attr.aria-label]="confirmDialog.rejectAriaLabel">
                <fa-icon [icon]="['fas', 'times']"></fa-icon>
            </p-button>
        </ng-template>
    </p-confirmDialog>

    <p-sidebar #pSidebar [(visible)]="pSidebar.visible" position="right" styleClass="w-4" >
        <div *ngIf="pSidebar.visible" class="pro-page pro-page-content">
            <app-formulario-sugestoes (closeCadastroSugestaoEvent)="pSidebar.visible = false"></app-formulario-sugestoes>
        </div>
    </p-sidebar>

    <!-- <button pButton class="pro-button" [style]="{ display: 'none', position: 'fixed', bottom: 0, 'align-self': 'center' }" (click)="pSidebar.visible ? pSidebar.visible = false : pSidebar.visible = true">
        Enviar críticas ou sugestões
    </button> -->
    
</main>
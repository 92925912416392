import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './core/pages/page-not-found/page-not-found.component';
import { PageForbiddenComponent } from './core/pages/page-forbidden/page-forbidden.component';

const routes: Routes = [

  { path: '', pathMatch: 'full', redirectTo: 'nucleos-urbanos' },
  { path: 'home', pathMatch: 'full', redirectTo: 'nucleos-urbanos' },

  { path: 'nucleos-urbanos', loadChildren: () => import('./nucleos-urbanos/nucleos-urbanos.module').then(m => m.NucleosUrbanosModule) },
  { path: 'lotes', loadChildren: () => import('./lotes/lotes.module').then(m => m.LotesModule) },
  { path: 'agentes', loadChildren: () => import('./agentes/agentes.module').then(m => m.AgentesModule) },
  { path: 'empresas', loadChildren: () => import('./empresas-coleta/empresas.module').then(m => m.EmpresasModule) },

  { path: '**', pathMatch: 'full', component: PageNotFoundComponent },
  { path: 'nao-autorizado', pathMatch: 'full', component: PageForbiddenComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

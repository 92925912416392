import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../auth/user';
import { Role } from '../auth/roles.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private _subject: BehaviorSubject<User> = new BehaviorSubject<User>({} as User);
  private _redirectUri: string = '';

  constructor(private readonly _keycloakService: KeycloakService) {
    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        const loggedIn = localStorage.getItem('loggedIn');

        if(loggedIn == null) {
          window.location.reload();
        }
      }
    }, false);
  }

  private async getCurrentUser(): Promise<User> {
    // TODO: obter roles do keycloak

    let user = await this._keycloakService.loadUserProfile();
    const tokenParsed = this._keycloakService.getKeycloakInstance().tokenParsed;

    const rolesFromToken = tokenParsed?.resource_access?.['reurb']?.roles;

    const roles: Role[] | null = rolesFromToken ? rolesFromToken.map((role: string) => { return Role[role as keyof typeof Role] }).filter((val) => val != null) : null;

    let currentUserName: string = (user.firstName ? user.firstName : '') + (user.lastName ? ' ' + user.lastName : '');
    let username: string = (user.username ? user.username : 'anonymous');

    let currentUser: User = {
      nome: currentUserName,
      username: username,
      roles: roles == null ? null : roles
    };
    
    return currentUser;
  }

  public getUser(): Observable<User> {
    this.getCurrentUser().then(
      (user) => {
        this._subject.next(user);
      },
      (error) => {
        console.error('error: falha ao obter usuário do keycloak' + error);
      }
    );

    return this._subject.asObservable();
  }

  public hasRole(role: Role): boolean {
    const tokenParsed = this._keycloakService.getKeycloakInstance().tokenParsed;

    const roles = tokenParsed?.resource_access?.['reurb']?.roles;

    return roles?.includes(Role[role]) || false;
  }

  public logout(): void {
    localStorage.removeItem('loggedIn');
    this._keycloakService.logout(this._redirectUri)
      .then(()=>{})
      .catch((erro) => {
        console.error(erro);
      });
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormularioSugestoesComponent } from './components/formulario-sugestoes/formulario-sugestoes.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PanelModule } from 'primeng/panel';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { ModalConfirmationComponent } from './components/modal-confirmation/modal-confirmation.component';


@NgModule({
  declarations: [
    FormularioSugestoesComponent,
    ModalConfirmationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    PanelModule,
    InputTextareaModule,
    ButtonModule
  ],
  exports: [
    FormularioSugestoesComponent
  ]
})
export class SharedModule { }
